<template>
  <div>
    <el-table
        :data="tableData"
        style="width: 99%;margin: 1% auto"
        row-key="id"
        border
        ref="table"
        :row-style="{height: '38px'}"
        :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
        :cell-style="cellStyleChange"
        @row-click="clickRow"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="moduleName"
          label="页面名称"
          sortable
          header-align="center"
          align="left">
      </el-table-column>
      <el-table-column
          prop="gradeNames"
          label="页面等级"
          align="center" >
      </el-table-column>
      <el-table-column
          label="是否已分配"
          align="center">
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.isAssign === 1 ? 'success' : 'danger'"
              disable-transitions>{{ scope.row.isAssign === 1 ? "已分配" : "未分配" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center">
        <template slot-scope="scope">
          <el-button size="mini"
                     type="primary"
                     @click="editViewFilter(scope.row)">修改
          </el-button>
          <el-button size="mini"
                     type="success"
                     :disabled="scope.row.isAssign === 1"
                     @click="assign(scope.row)">一键分配
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :title="'页面等级修改('+dialogTitle+')'"
        width="25%"
        top="25vh"
        :visible.sync="dialogState"
        :modal-append-to-body="false"
        @close="dialogState = false">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="20">
            <el-form-item label="页面等级" prop="gradeIds">
              <el-select v-model="form.gradeIds" multiple placeholder="课程类型" style="width: 100%">
                <el-option
                    v-for="item in gradeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogState = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit()" size="small">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "pc-view-list",
  data() {
    return {
      tableData: [],
      value: false,
      dialogState: false,
      dialogTitle: "",
      form: {
        id: "",
        gradeIds: [],
      },
      rules: {
        gradeIds: [
          {required: true, message: '请选择等级', trigger: 'change'},
        ],
      },
      gradeList:[],
      originalGradeIds: [],
    }
  },
  created: function () {
    this.queryModuleView();
    this.queryProductGradeIds()
  },
  methods: {
    queryProductGradeIds() {
      this.$selectUtils.queryProductGradeIds().then(response => {
        this.gradeList = response.data.data
      })
    },
    //单元格变色
    cellStyleChange: function (info) {
      let color = '#303133';
      if (info.row.grade === 2) {
        color = '#409EFF';
      } else if (info.row.grade === 3) {
        color = '#5CB87A';
      }
      return {color: color, padding: '0'};
    },
    //行点击展开
    clickRow: function (row, column, event) {
      if (column.label !== '操作') {
        this.$refs.table.toggleRowExpansion(row);
      }
    },
    queryModuleView: function () {
      this.$axios({
        method: "get",
        url: "/module/queryModuleList",
      }).then(response => {
        this.tableData = response.data.data;
      });
    },
    editViewFilter: function (data) {
      this.dialogState = true
      this.form.gradeIds = data.gradeIds
      this.originalGradeIds = data.gradeIds
      this.form.id = data.id
      this.dialogTitle = data.moduleName
    },
    assign: function (data) {
      data.children = null
      this.$axios({
        method: "post",
        url: "/module/assignModule",
        data: {
          gradeIds: data.gradeIds,
          id: data.id
        },
      }).then(response => {
        if (response.data.code === 200) {
          this.$message({
            message: response.data.msg,
            type: 'success',
            duration: 1000,
          });
          this.queryModuleView();
        } else {
          this.$message.error({
            message: response.data.msg,
            duration: 1000,
          });
        }
      });
    },
    grade(val) {
      switch (val) {
        case 1:
          return "基础版"
        case 2:
          return "经销商版"
        case 5:
          return "管理员"
      }
    },
    close() {
      this.dialogState = false
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            url: "/module/updateModuleViewFilter/",
            data: {
              ...this.form,
              originalGradeIds: this.originalGradeIds,
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message({
                message: response.data.msg,
                type: 'success',
                duration: 1000,
              });
              setTimeout(()=>{
                this.queryModuleView()
                this.dialogState = false
              }, 1000)
            } else {
              this.$message.error({
                message: response.data.msg,
                duration: 1000,
              });
            }
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>