import { render, staticRenderFns } from "./pc-view-list.vue?vue&type=template&id=50c2d787&scoped=true&"
import script from "./pc-view-list.vue?vue&type=script&lang=js&"
export * from "./pc-view-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c2d787",
  null
  
)

export default component.exports